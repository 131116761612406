import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  forwardRef,
} from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import SVGInline from 'react-svg-inline';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';

import Paginator from 'components/Paginator';

import { actions as cashierJackpotActions } from 'features/cashierJackpot';

import calendarSvg from './img/calendar.svg';

import './CashierJackpot.scss';

const b = block('cashier-jackpot');
const itemsOnPage = 5;

const CashierJackpot = () => {
  const dispatch = useDispatch();
  const styleDatePicker = require('react-datepicker/dist/react-datepicker.css');

  const locale = useSelector(state => state.locale.locale);
  const role = useSelector(state => state.auth.role, shallowEqual);
  const { actionProcessing, startAmount, lastWinners } = useSelector(
    state => state.cashierJackpot,
    shallowEqual
  );

  const [page, changePage] = useState(0);
  const [from, changeFrom] = useState(
    dayjs(new Date()).add(-3, 'day').format('YYYY-MM-DDTHH:mm:ss')
  );
  const [to, changeTo] = useState(
    dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss')
  );
  const [type, changeType] = useState('MINI');
  const actualList = useMemo(
    () => lastWinners.filter(item => item.name === type),
    [lastWinners, type]
  );

  useEffect(() => {
    dispatch(
      cashierJackpotActions.getCashierJackpot({
        from: dayjs(from).format('YYYY-MM-DDTHH:mm:ss'),
        to: dayjs(to).format('YYYY-MM-DDTHH:mm:ss'),
      })
    );
  }, []);

  const onSubmit = useCallback(
    () =>
      dispatch(
        cashierJackpotActions.getCashierJackpot({
          from: dayjs(from).format('YYYY-MM-DDTHH:mm:ss'),
          to: dayjs(to).format('YYYY-MM-DDTHH:mm:ss'),
        })
      ),
    [from, to, dispatch]
  );

  const ExampleCustomInput = forwardRef(
    ({ value, onClick, ...restProps }, ref) => {
      return (
        <div className={b('custom-date-input')} onClick={onClick} ref={ref}>
          <span className={b('custom-date-input-value')}>
            {dayjs(from).format('YYYY-MM-DD HH:mm:ss')}
          </span>
          <SVGInline
            className={b('custom-date-input-icon')}
            svg={calendarSvg}
          />
        </div>
      );
    }
  );
  const ExampleCustomInput2 = forwardRef(({ value, onClick }, ref) => (
    <div className={b('custom-date-input')} onClick={onClick} ref={ref}>
      <span className={b('custom-date-input-value')}>
        {dayjs(to).format('YYYY-MM-DD HH:mm:ss')}
      </span>
      <SVGInline className={b('custom-date-input-icon')} svg={calendarSvg} />
    </div>
  ));

  const pageCount = useMemo(
    () => Math.ceil(actualList.length / itemsOnPage),
    [actualList]
  );

  const list = useMemo(
    () =>
      actualList
        .slice(page * itemsOnPage, (page + 1) * itemsOnPage)
        .map(item => (
          <div className={b('list-row')}>
            <span className={b('list-row-item', { type: 'left' })}>
              {item.id}
            </span>
            <span className={b('list-row-item')}>
              {item.winners.winner_name}
            </span>
            <span className={b('list-row-item')}>{item.winners.win_date}</span>
            <span className={b('list-row-item', { type: 'right' })}>
              {item.winners.win_amount}
            </span>
          </div>
        )),
    [actualList, page]
  );

  if (+role !== 1) return null;
  return (
    <section className={b()}>
      <div className={b('type-switcher')}>
        <div
          className={b('type-switcher-item', { active: type === 'MINI' })}
          onClick={() => {
            changeType('MINI');
            changePage(0);
          }}
        >
          MINI
        </div>
        <div
          className={b('type-switcher-item', { active: type === 'MINOR' })}
          onClick={() => {
            changeType('MINOR');
            changePage(0);
          }}
        >
          MINOR
        </div>
      </div>

      <div className={b('start-amount')}>
        <p className={b('start-amount-title')}>{locale.startAmount}</p>
        <div className={b('start-amount-value')}>{startAmount[type]}</div>
      </div>

      <div className={b('title')}>{locale.jackpotWinnersList}</div>
      <div className={b('inputs')}>
        <div className={b('input-date')}>
          <div className={b('input-date-label')}>{`${locale.startDate}:`}</div>
          <div className={b('input-date-value')}>
            <DatePicker
              className={styleDatePicker}
              selected={new Date(from)}
              onChange={date =>
                changeFrom(dayjs(date).format('YYYY-MM-DDTHH:mm:ss'))
              }
              customInput={<ExampleCustomInput />}
              showTimeSelect
            />
          </div>
        </div>
        <div className={b('input-date')}>
          <div className={b('input-date-label')}>{`${locale.endingDate}:`}</div>
          <div className={b('input-date-value')}>
            <DatePicker
              selected={new Date(to)}
              onChange={date =>
                changeTo(dayjs(date).format('YYYY-MM-DDTHH:mm:ss'))
              }
              customInput={<ExampleCustomInput2 />}
              showTimeSelect
            />
          </div>
        </div>
      </div>

      <div className={b('button-container')}>
        <div
          className={b('button', { isLoading: actionProcessing })}
          onClick={actionProcessing ? f => f : onSubmit}
        >
          {locale.display}
        </div>
      </div>
      <article className={b('list')}>
        <div className={b('list-header')}>
          <span className={b('list-header-item', { type: 'left' })}>
            {locale.winnerID}
          </span>
          <span className={b('list-header-item')}>{locale.nickname}</span>
          <span className={b('list-header-item')}>{locale.winDate}</span>
          <span className={b('list-header-item', { type: 'right' })}>
            {locale.amountOfTheWinningJackpot}
          </span>
        </div>
        {list}
      </article>
      <div className={b('paginator')}>
        <Paginator
          page={pageCount}
          currentPage={page}
          onPageClick={val => changePage(val)}
          locale={locale}
        />
      </div>
    </section>
  );
};

export default CashierJackpot;
