import React from "react";
import block from "bem-cn";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat'

import "./TransactionItem.scss";

dayjs.extend(customParseFormat)

const TransactionItem = ({ item }) => {
  const b = block("transaction-item");

  return (
    <div className={b()}>
      <span className={b("column", { type: "left" })}>{item.email}</span>
      <span className={b("column", { type: "center" })}>
        {dayjs(+dayjs(item.date.toString(), "YYYY.MM.DD HH:mm").add(dayjs().utcOffset(), 'minute')).format("YYYY.MM.DD HH:mm")}
      </span>
      <span
        className={b(
          "column-amount",
          { type: "right" },
          { colored: item.amount > 0 }
        )}
      >
        {item.amount}
      </span>
      <span className={b("column", { type: "right" })}>{item.balance}</span>
    </div>
  );
};

export default TransactionItem;
