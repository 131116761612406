import React from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';

import './Table.scss';


const Table = ({locale, heads, items}) => {
  const b = block('table');
  const betTypeLocale = locale.betTypes
  const rows = [
    {part: {one: true}, order(idx){ return idx < 5 }},
    {part: {two: true}, order(idx){ return idx >= 5 }}
  ]
  return (
    <div className={b()}>
      {items.length
        ? items.map((item) => {
          const headKeys = Object.keys(heads).filter((headKey) => item[headKey])
          return (
            <div className={b('row')}>
              {rows.map(row=> <div className={b('row-part', {...row.part})}>
                {headKeys.map((headKey, idx) =>
                  row.order(idx) && <p className={b('row-p')}>
                    <span className={b('row-span')}>{heads[headKey]}:</span><br/>
                    {betTypeLocale[item[headKey]] ?? item[headKey]}
                  </p>)}
              </div>)}
            </div>
          )
        })
        : <h3 className={b('no-bets')}>{locale.noBets}</h3>
      }
    </div>
  )
};

Table.propTypes = {
  locale: PropTypes.object.isRequired,
  heads: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.exact({
    id: PropTypes.number,
    type: PropTypes.string,
    fromUserId: PropTypes.number,
    toUserId: PropTypes.number,
    value: PropTypes.string,
    currency: PropTypes.string,
    note: PropTypes.string,
    createdAt: PropTypes.string,
    description: PropTypes.string,
    balanceBefore: PropTypes.string
  })).isRequired
}

export default Table