import React from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import SVGInline from "react-svg-inline/src";

import DateInput from 'components/DateInput';
import Button from 'components/Button';

import calendarSVG from '../../img/calendar.svg'

import './BetsFilter.scss';

const BetsFilter = props => {
  const b = block('slots-bets-filter');
  const { locale, beginDate, endDate, onSubmit, onChangeBeginDate, onChangeEndDate } = props;
  return (
    <form
      className={b()}
      onSubmit={e => {
        e.preventDefault();
        onSubmit();
      }}>
      <div className={b('filters')}>
        <div className={b('wrapper')}>
          <p>{locale.startDate}:</p>
          <div className={b('filter-row')}>
            <div className={b('input')}>
              <SVGInline svg={calendarSVG} className={b('calendar-icon')}/>
              <DateInput
                name="beginDate"
                size="middle"
                rounded
                format="dd.MM.yyyy"
                value={beginDate}
                onChange={onChangeBeginDate}
              />
            </div>
          </div>
        </div>
        <div className={b('wrapper')}>
          <p>{locale.endingDate}:</p>
          <div className={b('filter-row')}>
            <div className={b('input')}>
              <SVGInline svg={calendarSVG} className={b('calendar-icon')}/>
              <DateInput
                name="endDate"
                size="middle"
                rounded
                format="dd.MM.yyyy"
                value={endDate}
                onChange={onChangeEndDate}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={b('button')}>
        <Button text={locale.apply} rounded color="gold" type="submit" size={'low'}/>
      </div>
    </form>
  );
};

BetsFilter.propTypes = {
  locale: PropTypes.object.isRequired,
  beginDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  onChangeBeginDate: PropTypes.func.isRequired,
  onChangeEndDate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default BetsFilter