import {useState} from "react";

const useProviderFilters = (locale) => {

	let staticFilters = {
		betType: {name: locale.betTypes.all , value: 'all'},
	}

	const [filters, setFilters] = useState({...staticFilters});

	const initialFilters = filters.betType.name ? filters : staticFilters

	return {filters: initialFilters, setFilters}
}

export default useProviderFilters