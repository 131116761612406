export class BetsApiConverter {
	convertTransferList = ({transfers, count}) => {
		return {
			count,
			transfers: transfers.map((t) => ({
        id: t?.id,
        type: t?.type,
        fromUserId: t?.from_user_id,
        toUserId: t?.to_user_id,
        value: t?.value,
        currency: t?.currency,
        note: t?.note,
        createdAt: t?.created_at,
        description: t?.description,
        balanceBefore: t?.balance_before
      }))
		}
	}
}