import React, { useState, useCallback, useEffect, useMemo } from "react";
import block from "bem-cn";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import SVGInline from "react-svg-inline";
import dayjs from "dayjs";

import Input from "components/Input";
import { actions } from "../redux";
import searchSvg from "./img/search.svg";

import "./Total.scss";

const b = block("total");

const Total = () => {
  const dispatch = useDispatch();
  const locale = useSelector((state) => state.locale.locale);
  const totalInfo = useSelector((state) => state.total.totalInfo);
  const name = useSelector((state) => state.auth.name, shallowEqual);
  const role = useSelector((state) => state.auth.role, shallowEqual);
  const userList = useSelector((state) => state.total.users, shallowEqual);
  const actionProcessing = useSelector((state) => state.total.actionProcessing);
  const [fromDate, changeFromDate] = useState(
    dayjs(new Date()).add(-3, "day").format("YYYY-MM-DD"),
  );
  const [toDate, changeToDate] = useState(
    dayjs(new Date()).format("YYYY-MM-DD"),
  );
  const [nickname, setNickname] = useState("");

  useEffect(() => {
    dispatch(actions.getTotal(fromDate, toDate, nickname));
  }, []);
  const callBack = useCallback(
    () => dispatch(actions.getTotal(fromDate, toDate, nickname)),
    [fromDate, toDate, nickname, dispatch],
  );

  const onUserClick = useCallback(
    (id) => {
      if (!actionProcessing) {
        dispatch(actions.getUserData(id, fromDate, toDate));
      }
    },
    [actionProcessing, dispatch, fromDate, toDate],
  );

  const cashier = useMemo(() => {
    return (
      role === 1 && (
        <div key={name} className={b("row", { type: "bold" })}>
          <span className={b("td", { type: "left" })}>{name}</span>
          <span className={b("td")}>{totalInfo.total}</span>
          <span className={b("td")}>{totalInfo.credits}</span>
          <span className={b("td")}>{totalInfo.debits}</span>
        </div>
      )
    );
  }, [role, totalInfo, name, b]);

  const users = useMemo(
    () =>
      userList.length === 1 && !!!userList[0].userId
        ? null
        : userList.map((t) => (
            <div
              key={t.userId}
              className={b("row", {
                isRedBorder: t.isWithdrawalsBlocked,
              })}
              onClick={() => onUserClick(t.userId)}
            >
              <span className={b("td", { type: "left" })}>{t.username}</span>
              <span className={b("td")}>{t.total}</span>
              <span className={b("td")}>{t.credits}</span>
              <span className={b("td")}>{t.debits}</span>
            </div>
          )),
    [b, onUserClick, userList],
  );

  return (
    <section className={b()}>
      <div className={b("title")}>{locale.login}</div>
      <div className={b("input-block")}>
        {`${locale.startDate}:`}
        <Input
          value={fromDate}
          onChange={(e) => changeFromDate(e.currentTarget.value)}
          type="date"
        />
      </div>
      <div className={b("input-block")}>
        {`${locale.endingDate}:`}
        <Input
          value={toDate}
          onChange={(e) => changeToDate(e.currentTarget.value)}
          type="date"
        />
      </div>
      <div className={b("input-block")}>
        {locale.nickname}
        <Input
          value={nickname}
          onChange={(e) => setNickname(e.currentTarget.value)}
        />
      </div>
      <div className={b("bottom")}>
        <div
          className={b("button", { isLoading: actionProcessing })}
          onClick={actionProcessing ? (f) => f : callBack}
        >
          {locale.display}
          {/* <SVGInline svg={searchSvg} className={b('ok').toString()} /> */}
        </div>
      </div>
      <div className={b("summary")}>
        <div>{locale.fullTotal}</div>
        <div>{totalInfo.total}</div>
      </div>
      <article className={b("result")}>
        <div className={b("result-header")}>
          <span className={b("td", { type: "left" })}>{locale.nickname}</span>
          <span className={b("td")}>{locale.total}</span>
          <span className={b("td")}>{locale.deposit}</span>
          <span className={b("td")}>{locale.withdraw}</span>
        </div>
        {cashier}
        {users}
      </article>
    </section>
  );
};

export default Total;
